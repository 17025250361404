<template>
  <v-sheet
      v-if="selectedContactId || !SM"
      class="pa-6 fill-height"
      :class="[{'fullscreen' : SM }]"
  >
    <v-btn
        v-if="SM"
        :color="wsACCENT"
        @click="returnBack"
        :style="`background-color: ${wsBACKGROUND}`"
        outlined
        small
        icon
    >
      <v-icon>mdi-chevron-left</v-icon>
    </v-btn>

    <v-sheet   class="wsRoundedHalf overflow-y-auto fill-height d-flex align-center justify-center pa-6" >

      <div v-if="selectedContactId">

        <div class="d-flex justify-center">
          <v-avatar class="mr-3 " size="82" :color="wsLIGHTACCENT">
            <v-img  v-if="selectedContact.img" :src="selectedContact.img"></v-img>
            <v-icon v-else dark>mdi-account</v-icon>
          </v-avatar>
        </div>

        <h2 class="text-center"> {{ selectedContact.name }}</h2>
        <h3 class="text-center font-weight-medium"> {{ $t(selectedContact.type) }}</h3>

        <v-btn @click="gotoChat" :disabled="LOADING" block outlined :color="wsACCENT" class="noCaps mt-6"> {{ $t('SendMessage') }}</v-btn>

      </div>
      <div v-else>
        <h1 class="text-center"> {{ $t('NoContactSelected') }}</h1>
      </div>

    </v-sheet>
  </v-sheet>


</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "messengerChatInfo",
  computed : {
    ...mapState('messenger', [
      'selectedContactId',
      'selectedContact',
      'activeChats'
    ]),
  },
  methods : {
    ...mapActions('messenger', [
      'ADD_CHAT'
    ] ),

    returnBack() {
      this.$store.state.messenger.selectedContactId = ''
      this.$store.state.messenger.selectedContact = {}
    },

    async gotoChat() {
      let chat = null
      if ( this.activeChats.length > 0 ) {
        chat = this.$store.state.messenger.activeChats.find(
            el=> el.type ==='personal' && el.users.includes(this.selectedContact.uuid)
        );
      }

      if ( !chat ) {
        let result = await this.ADD_CHAT(this.selectedContact)
        chat = result
      }

      this.$store.state.messenger.selectedChat = chat
      this.$store.state.messenger.selectedChatId = chat.uuid
      this.$store.state.messenger.selectedContact = {}
      this.$store.state.messenger.selectedContactId = null
      this.$store.state.messenger.sidebarType = 'active_chats'

      this.$store.state.messenger.search = ''

    }
  }
}
</script>

<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>